<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="16" :offset="4">
        <el-form>
          <el-form-item label="抽样规则配置" style="font-size:20px;">
            <el-table :data="rulesData" class="mb-4 mt-4" max-height="650px">
              <el-table-column label="批量大小（双）" min-width="180px;">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.minPairs" style="display:inline!important;" /> ~
                  <el-input v-model="scope.row.maxPairs" style="display:inline!important;" />
                </template>
              </el-table-column>
              <el-table-column label="抽检数量（双）" min-width="200px;">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.spotCheckPairs" oninput="value=value.replace(/[^0-9.]/g,'')" :disabled="scope.row.spotCheckPairs==='All'" />
                </template>
              </el-table-column>
              <el-table-column min-width="100px;">
                <template slot-scope="scope">
                  <i
                    v-if="scope.$index!==0"
                    class="el-icon-remove-outline"
                    style="margin-right:20%;font-size:18px;"
                    @click="deleteRules(scope.row,scope.$index)"
                  />
                  <i
                    v-if="scope.$index+1==rulesData.length"
                    class="el-icon-circle-plus-outline"
                    style="font-size:18px;"
                    @click="addRules"
                  />
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <!--           <el-form-item label="稽查仓管员配置" class="mt-4">
            <el-form-item label="稽查地点" :class="$i18n.locale" class="mt-4">
              <el-select
                v-model="form.returnWarehouseId"
                clearable
                filterable
                multiple
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option label="仓库" value="1" />
                <el-option label="工厂" value="2" />
              </el-select>
            </el-form-item>
          </el-form-item> -->
        </el-form>
      </el-col>
    </el-row>
    <el-row style="text-align:center;">
      <el-col :span="2" :offset="11"><el-button type="primary" @click="_saveConfig">保存</el-button></el-col>
    </el-row>
  </div>
</template>

<script>
import { spotCheckList, saveConfig } from '@/api/audit-api.js'
export default {
  data() {
    return {

      rulesData: [{
        minPairs: 1,
        maxPairs: 100,
        spotCheckPairs: 'All'
      }]

    }
  },
  watch: {
    'rulesData': {
      handler(val) {
        if (val) {
          // console.log(val)
          for (let i = 0; i < val.length; i++) {
            if (!(/^[0-9]+$/).test(val[i].minPairs)) {
              val[i].minPairs = ''
            }
            if (!(/^[0-9]+$/).test(val[i].maxPairs)) {
              val[i].maxPairs = ''
            }
          }
        }
      },
      deep: true
    }
  },
  created() {

  },
  mounted() {
    this._spotCheckList()
  },
  methods: {
    async _spotCheckList() {
      const { code, datas } = await spotCheckList()

      if (code === 0) {
        this.rulesData.length === 0 && this.rulesData.push({ minPairs: 1, maxPairs: 100, spotCheckPairs: 'All' })
        this.rulesData = datas.map((item, index) => {
          index === 0 ? item.spotCheckPairs = 'All' : ''
          return item
        })
      }
    },
    addRules() {
      this.rulesData.push({ maxPairs: '', minPairs: '', spotCheckPairs: '' })
    },
    deleteRules(item, index) {
      this.rulesData.splice(index, 1)
    },
    async _saveConfig() {
      console.log(this.rulesData)
      const valid1 = this.rulesData.find(item => item.maxPairs && (Number(item.minPairs) > Number(item.maxPairs)))
      const valid2 = this.rulesData.find(item => !item.minPairs || !item.maxPairs || !item.spotCheckPairs)
      const valid3 = this.rulesData.find(item => Number(item.spotCheckPairs) > Number(item.maxPairs))
      if (valid1) {
        this.$message.error(`存在无效批量数据区间 ${valid1.minPairs}-${valid1.maxPairs}`)
        return
      }
      if (valid2) {
        this.$message.error(`存在无效批量数据区间`)
        return
      }
      if (valid3) {
        this.$message.error(`存在无效抽检双数 ${valid3.spotCheckPairs}`)
        return
      }
      console.log(this.rulesData)
      const { code, msg } = await saveConfig(this.rulesData)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this._spotCheckList()
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-table td{
	border: none;
}
.el-table::before {//去掉最下面的那一条线
	height: 0px;
}
/deep/.el-table th.is-leaf{
  border: none;
}
/deep/.el-table tbody tr:hover>td {
    background-color:#ffffff!important
}
 /deep/   .el-input--medium .el-input__inner{
  width:45%;
}
/deep/ .el-form-item--medium .el-form-item__label{
  font-size:20px;
}
</style>
